var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.supplier ? _c('v-card', {
    staticClass: "page-supplierPrivateWarehousesManagement fill-height flat pa-5"
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('PrivateWarehouses')) + " ")]), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "items": _vm.privateWarehouses,
      "headers": _vm.headersPrivateWarehouses
    },
    scopedSlots: _vm._u([{
      key: `item.company`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: `item.address`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.address.city) + " (" + _vm._s(_vm.$t(`countries.${item.address.country}`)) + ") ")])];
      }
    }, {
      key: `item.billing`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.billing ? _c('v-icon', {
          attrs: {
            "color": "orange"
          }
        }, [_vm._v(" fas fa-file-invoice-dollar ")]) : _c('v-icon', {
          attrs: {
            "color": "grey"
          }
        }, [_vm._v(" fas fa-file-invoice-dollar ")])];
      }
    }], null, true)
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }