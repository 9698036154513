<template lang="html" src="./pageSupplierPrivateWarehousesManagement.template.vue"></template>

<style lang="scss" src="./pageSupplierPrivateWarehousesManagement.scss"></style>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSupplierPrivateWarehousesManagement.i18n.json');

export default {
  name: 'PageSupplierPrivateWarehousesManagement',
  components: { },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headersPrivateWarehouses: [],
      loading: false,
      privateWarehouses: [],
      supplier: null
    };
  },
  created() {
    this.headersPrivateWarehouses = [
      {
        text: this.$t('Warehouse'),
        value: 'name'
      },
      {
        text: this.$t('City'),
        value: 'address'
      },
      {
        text: this.$t('Billing'),
        value: 'billing'
      },
    ];
    this.getSupplier();
    this.getSupplierPrivateWarehouses();
  },
  methods: {
    /**
     * GET SUPPLIER
     */
    getSupplier() {
      this.loading = true;
      this.$apiInstance
        .getSupplier(this.$route.params.supplierUUID)
        .then(
          (supplierData) => {
            this.supplier = supplierData;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getSupplierPrivateWarehouses() {
      this.$apiInstance.getSupplierPrivateWarehouses(
        this.$route.params.supplierUUID
      ).then(data => {
        this.privateWarehouses = data;
      });
    },

  },
};
</script>